<template>
  <a href="#!" @click.prevent="showPropertyTap" class="col-12 col-lg-6">
    <div class="property-wrapper" ref="propertyWrapper">
      <div class="image-wrapper">
        <img v-lazy="getPropertyImage" alt="" />
      </div>
      <div class="info-wrapper">
        <h5 class="title">{{ property.title }}</h5>

        <div class="addition-date mt-3">
          <span class="ml-3">تاريخ الإضافة:</span>
          <span class="text-muted">{{
            dateFormat(property.created_at, "YYYY/M/D")
          }}</span>
        </div>

        <div class="details-wrapper mt-3">
          <div class="d-flex align-items-start">
            <div class=" details-item details-item">
              <div class="details-title text-muted">عدد الغرف</div>
              <div class="details-info">
                <img
                  style="opacity: 0.5" src="@/assets/img/rooms.png"
                  width="25px"
                  height="auto"
                  alt=""
                  class="ml-2"
                />
                <ArabicNumber :number="property.roomsCount" />
              </div>
            </div>

            <div class=" details-item">
              <div class="details-title text-muted">دورات المياه</div>
              <div class="details-info">
                <img
                  style="opacity: 0.5" src="@/assets/img/bath.png"
                  width="25px"
                  height="auto"
                  alt=""
                  class="ml-2"
                />

                <ArabicNumber :number="property.bathsCount" />
              </div>
            </div>

            <div class="details-item d-none d-sm-block">
              <div class="details-title text-muted">المطبخ</div>

              <div class="details-info">
                  <img style="opacity: 0.3" src="@/assets/img/kitchen.png" width="25px" height="auto" alt="" class="ml-2">

                {{ property.isAmericanKitchen ? "أمريكي" : "مستقل" }}
              </div>
            </div>

            <div class="details-item d-none d-sm-block">
              <div class="details-title text-muted">الأثاث</div>

              <div class="details-info">
                  <img style="opacity: 0.5" src="@/assets/img/furniture.png" width="25px" height="auto" alt="" class="ml-2">

                {{ property.isWithFurniture ? "مؤثث" : "بدون" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import PropertiesMixin from '@/mixins/properties'
import CategoriesMixin from '@/mixins/categories'

export default {
  mixins: [PropertiesMixin, CategoriesMixin],

  methods: {
    showPropertyTap () {
      this.selectProperty(this.property)
      let category = this.categories.find(cat => cat.id == this.property.category_id)
      this.$router.push({ name: 'property.show', params: { categoryName: category.name, propertyNumber: this.property.number } })
    }
  },

  computed: {
    getPropertyImage () {
      return this.property.images[0] ? this.property.images[0].link : require('@/assets/img/image-placeholder.png')
    }
  },

  props: ["property"],
};
</script>