var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",attrs:{"id":"footer-wrapper"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-4 footer-section"},[_c('ul',{staticClass:"list-unstyled footer-list"},[_c('li',[_c('a',{attrs:{"href":_vm.VUE_APP_USER_URL + '/login'}},[_vm._v("تسجيل دخول العميل")])]),_c('li',[_c('a',{attrs:{"href":_vm.VUE_APP_AGENT_URL + '/login'}},[_vm._v("تسجيل دخول الموظف")])])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-4 footer-section"},[_c('ul',{staticClass:"list-unstyled footer-list"},_vm._l((_vm.categoriesForFooter),function(cat,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":{
                  name: 'category',
                  params: { categoryName: cat.name },
                }}},[_vm._v(_vm._s(cat.name))])],1)}),0)]),(_vm.settings)?_c('div',{staticClass:"col-12 col-md-4 text-center footer-section"},[_c('div',{staticClass:"social-links text-center"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col d-flex align-items-center justify-content-center"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.settings.mobile))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.settings.email))])])])])]),_vm._m(1)]):_vm._e()])])]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('img',{attrs:{"id":"snapchat-img","src":require("@/assets/img/snapchat.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-rights"},[_c('span',[_vm._v("جميع الحقوق محفوظة. أثبات العقارية ©2020")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"developer-link d-block"},[_c('span',[_vm._v("تطوير")]),_vm._v(" "),_c('a',{attrs:{"href":"https://khaledapps.com","target":"_blank"}},[_vm._v("khaledapps")])])
}]

export { render, staticRenderFns }