import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({

        })
    },

    computed: {
        ...mapGetters({
            settings: 'settings/settings'
        })
    }
}