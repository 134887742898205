<template>
  <div>
    <div class="home-section">
      <div class="container">
        <div id="properties-search">
          <multiselect
            dir="rtl"
            class="text-right"
            v-model="searchProperty"
            :options="properties"
            placeholder="البحث عن عقار"
            label="number"
            track-by="number"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template
              ><span slot="noOptions">لا توجد خيارات</span></template
            > </multiselect
          >
        </div>
        <div class="row">
          <div
            v-for="(cat, i) in categories"
            :key="i"
            class="col-sm-6 col-md-4"
          >
            <div
              @click="showCategoryTap(cat)"
              class="category-item pointer mb-4 p-5 bold"
              :style="
                'background-color: ' +
                cat.color +
                '; color: ' +
                cat.textColor +
                ';'
              "
            >
              <span>{{ cat.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <carousel
      class="my-5"
      v-if="adsWithImages.length > 0"
      :autoplayTimeout="4000"
      :controls="true"
      style="height: 500px"
      :perPage="1"
      :autoplay="true"
      easing="ease-in"
      :loop="true"
      :paginationEnabled="true"
      indicators="hover"
      dir="ltr"
    >
      <slide
        v-for="(ad, i) in adsWithImages"
        :key="i"
        style="height: 100%; width: 100%"
      >
        <a href="#!" @click.prevent="showAdTap(ad)">
          <img
            v-lazy="ad.images[0].link"
            height="100%"
            width="100%"
            style="object-fit: contain"
            alt=""
          />
        </a>
      </slide>
    </carousel>
  </div>
</template>

<script>
// import PropertyItem from "@/components/general/property-item";
import PropertiesMixin from "@/mixins/properties";
import CategoriesMixin from "@/mixins/categories";
import AdMixin from "@/mixins/ads";

export default {
  mixins: [PropertiesMixin, CategoriesMixin, AdMixin],

  data () {
    return {
      searchProperty: null
    }
  },

  methods: {
    showAdTap(ad) {
      this.selectAd(ad);
      this.$router.push({ name: "ad", params: { adNumber: ad.number } });
    },

    showCategoryTap(cat) {
      this.selectCategory(cat);
      this.$router.push({
        name: "category",
        params: { categoryName: cat.name },
      });
    }
  },

  computed: {
    categoryProperties() {
      let properties = this.categories[0].properties.filter(
        (p) => p.client_id == null
      );
      return properties.slice(properties.length - 4, properties.length);
    },
  },

  components: {
    // PropertyItem,
  },

  watch: {
    searchProperty (val) {
      let category = this.categories.find(c => c.id == val.category_id)
      this.selectCategory(category)
      this.selectProperty(val)
      this.$router.push({ name: 'property.show', params: { categoryName: category.name, propertyNumber: val.number } })
    }
  }
};
</script>