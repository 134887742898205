import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/category'
import ShowProperty from '../views/property.vue'
import Ad from '../views/ad.vue'
import LeaseRequest from '../views/lease-request.vue'

Vue.use(VueRouter)

import HomeLayout from '@/layouts/home'
import MainLayout from '@/layouts/main'
Vue.component('home-layout', HomeLayout)
Vue.component('main-layout', MainLayout)

const routes = [{
        path: '/athbat.com/dist/:any',
        redirect: '/'
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            layout: 'home-layout'
        }
    },
    {
        path: '/category/:categoryName',
        name: 'category',
        component: Category,
        meta: {
            layout: 'main-layout'
        }
    },
    {
        path: '/category/:categoryName/:propertyNumber',
        name: 'property.show',
        component: ShowProperty,
        meta: {
            layout: 'main-layout'
        }
    },
    {
        path: '/ads/:adNumber',
        name: 'ad',
        component: Ad,
        meta: {
            layout: 'main-layout'
        }
    },
    {
        path: '/lease-request',
        name: 'leaseRequest',
        component: LeaseRequest,
        meta: {
            layout: 'home-layout'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

export default router