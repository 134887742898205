import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        categories: [],
        selectedCategory: null
    },

    getters: {
        categories: state => state.categories,
        selectedCategory: state => state.selectedCategory,
        categoriesForFooter: state => state.categories.slice(0, 4)
    },

    mutations: {
        SET_CATEGORIES: (state, payload) => state.categories = payload,
        SELECT_CATEGORY: (state, payload) => state.selectedCategory = payload
    },

    actions: {
        async fetchCategories({ commit }) {
            let { data } = await axios.get('categories')
            commit('SET_CATEGORIES', data.categories)

            return
        }
    }
}