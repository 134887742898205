// import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        properties: [],
        selectedProperty: null
    },

    getters: {
        properties: (state, getters, rootState, rootGetters) => {
            let properties = []
            console.log('rootGetters: ', rootGetters)
            rootGetters['categories/categories'].forEach(c => {
                c.properties.forEach(p => properties.push(p))
            })
            return properties
        },
        // properties: state => state.properties.filter(p => p.client_id == null),
        selectedProperty: state => state.selectedProperty,
        latestProperties: state => {
            return state.properties.filter(p => p.client_id == null).slice(state.properties.length - 4, state.properties.length)
        }
    },

    mutations: {
        SET_PROPERTIES: (state, payload) => state.properties = payload,
        SELECT_PROPERTY: (state, payload) => state.selectedProperty = payload
    },

    actions: {
        // async fetchProperties({ commit }) {
        //     let { data } = await axios.get('properties')
        //     commit('SET_PROPERTIES', data.properties)
        // }

        fetchProperties({ commit, rootState }) {
            let categories = rootState.categories.categories
            let properties = []
            categories.forEach(cat => {
                cat.properties.forEach(p => properties.push(p))
            })

            commit('SET_PROPERTIES', properties)
        }
    }
}