import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faMapMarkerAlt,
    faBars,
    faCheckCircle,
    faPlus,
    faTrash
} from '@fortawesome/free-solid-svg-icons'

import {} from '@fortawesome/free-regular-svg-icons'

import {
    faTwitter,
    faInstagram,
    faYoutube,
    faSnapchat
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faMapMarkerAlt,
    faTwitter,
    faInstagram,
    faYoutube,
    faSnapchat,
    faBars,
    faCheckCircle,
    faPlus,
    faTrash
)

Vue.component('fa', FontAwesomeIcon)