import Vue from 'vue'
import Vuex from 'vuex'

import properties from './properties'
import settings from './settings'
import categories from './categories'
import ads from './ads'
import headerImages from './headerImages'
import requests from './requests'
import agents from './agents'
import leaseRequests from './leaseRequests'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},

    mutations: {},

    actions: {
        init({ dispatch }) {
            dispatch('settings/fetchSettings')
            dispatch('categories/fetchCategories').then(() => {
                dispatch('properties/fetchProperties')
            })
            dispatch('ads/fetchAds')
            dispatch('headerImages/fetchHeaderImages')
            dispatch('agents/fetchRecords')
        },
    },

    modules: {
        properties,
        settings,
        categories,
        ads,
        headerImages,
        requests,
        agents,
        leaseRequests
    }
})