<template>
  <b-modal id="residential-lease-modal" title="العقد السكني" size="lg">
    <div class="container py-2">
      <div class="form-group row">
        <label for="nameAr" class="col-md-4 col-form-label text-md-right"
          >حدد الموظف</label
        >

        <div class="col-md-8">
          <multiselect
            dir="rtl"
            class="text-right"
            v-model="form.agent"
            :options="agents"
            placeholder="حدد الموظف"
            label="name"
            track-by="name"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
            autofocus
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template><span slot="noOptions">لا توجد خيارات</span></template>
          </multiselect>
        </div>
      </div>

      <fieldset class="border p-2 mb-3">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المؤجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterIdNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="form.renterName"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الجوال المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterMobile"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterBirthDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterBirthMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterBirthYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الشقق</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterApartmentsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الغرف</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterRoomsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الصالات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterHallsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الحمامات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterBathroomsCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد المكيفات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterACCount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مفروش/غير مفروش</label
          >

          <div class="col-md-8">
            <select class="form-control" v-model="form.renterIsFurnished">
              <option value="مفروش">مفروش</option>
              <option value="غير مفروش">غير مفروش</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع عداد الكهرباء</label
          >

          <div class="col-md-8">
            <select class="form-control" v-model="form.renterElectricType">
              <option value="مستقل">مستقل</option>
              <option value="مشترك">مشترك</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم حساب عداد الكهرباء</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="form.renterElectricMeterNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ بداية العقد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterLeaseStartDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterLeaseStartMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.renterLeaseStartYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مبلغ الإيجار السنوي</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.renterLeaseYearlyAmount"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع الدفعات</label
          >

          <div class="col-md-8">
            <select
              class="form-control"
              v-model="form.renterLeasePaymentPeriod"
            >
              <option value="شهري">شهري</option>
              <option value="ربع سنوي">ربع سنوي</option>
              <option value="نصف سنوي">نصف سنوي</option>
              <option value="سنوي">سنوي</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الآيبان البنكي</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="form.renterIban"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >صورة واضحة للصك</label
          >

          <div class="col-md-8">
            <div
              class="pointer d-inline-block mt-3"
              @click="$refs.leaseImage.click()"
            >
              <div class="m-auto">
                <img
                  ref="leasePreview"
                  class="img-preview m-auto"
                  :src="getLeaseImage"
                  alt=""
                />
              </div>
              <span class="text-muted small">تحديد الصورة</span>
              <input
                ref="leaseImage"
                type="file"
                style="display: none"
                @change="updateLeaseImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <fieldset class="border p-2 mb-3">
          <legend class="w-auto" style="font-size: 16px">العنوان الوطني</legend>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >رقم المبنى</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="form.renterBuildingNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرقم الإضافي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="form.renterAdditionalNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرمز البريدي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                required
                class="form-control"
                v-model="form.renterPostalCode"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الموقع على الخريطة</label
            >

            <div class="col-md-8">
              <GmapMap
                ref="map"
                :center="{ lat: form.renterLat, lng: form.renterLng }"
                :zoom="14"
                style="width: 100%; height: 300px"
                @click="updateMarker"
              >
                <GmapMarker
                  :position="{ lat: form.renterLat, lng: form.renterLng }"
                  :clickable="false"
                  :draggable="false"
                  @click="center = { lat: form.renterLat, lng: form.renterLng }"
                />
              </GmapMap>
            </div>
          </div>
        </fieldset>
      </fieldset>

      <fieldset class="border p-2 my-5">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المستأجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.residentIdNumber"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="form.residentName"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الجوال المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="form.residentMobile"
              required
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.residentBirthDay"
                  placeholder="يوم"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.residentBirthMonth"
                  placeholder="شهر"
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.residentBirthYear"
                  placeholder="سنة"
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="mt-5 text-center">
        <p>
          <fa icon="check-circle" style="color: green" />
          أتعهد بأن جميع البيانات التي قمت بإرفاقها صحيحة وأتحمل مسؤوليتها.
        </p>
      </div>
    </div>

    <template v-slot:modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="md"
            style="position: relative"
            @click="submit"
          >
            <i class="fa fa-check"></i>
            <span>إرسال</span>
          </b-button>
        </div>
        <button
          @click="$bvModal.hide('residential-lease-modal')"
          class="float-left btn btn-light"
          style="box-shadow: none"
        >
          تراجع
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RequestsMixin from "@/mixins/requests";
import AgentsMixin from '@/mixins/agents'
import LeaseRequestMixin from '@/mixins/leaseRequests'

export default {
  mixins: [RequestsMixin, AgentsMixin, LeaseRequestMixin],

  data() {
    return {
      form: {
        agent: null,
        renterIdNumber: null,
        renterName: null,
        renterMobile: null,
        renterBirthDay: null,
        renterBirthMonth: null,
        renterBirthYear: null,
        renterApartmentsCount: null,
        renterLeasePaymentPeriod: "شهري",
        renterElectricType: "مستقل",
        renterElectricMeterNumber: null,
        renterLeaseStartDay: null,
        renterLeaseStartMonth: null,
        renterLeaseStartYear: null,
        renterLeaseYearlyAmount: null,
        renterIban: null,
        renterLeaseNumber: null,
        renterBuildingNumber: null,
        renterAdditionalNumber: null,
        renterPostalCode: null,
        renterLat: 24.627463,
        renterLng: 46.691385,
        renterRoomsCount: null,
        renterHallsCount: null,
        renterBathroomsCount: null,
        renterACCount: null,
        renterIsFurnished: 'مفروش',

        residentIdNumber: null,
        residentName: null,
        residentMobile: null,
        residentBirthDay: null,
        residentBirthMonth: null,
        residentBirthYear: null,
      },

      leaseImage: null
    };
  },

  methods: {
    submit() {
      let data = this.form
      data.type = 'residential'
      if (!this.leaseImage) {
        this.showSwal({
          success: false,
          message: 'فضلًا قم بإضافة جميع البيانات والصور المطلوبة'
        })
      }

      if (!this.form.agent) {
        this.showSwal({
          success: false,
          message: 'فضلًا قم بتحديد الموظف المطلوب'
        })
      }
      
      data.leaseImage = this.leaseImage
      this.addLeaseRequest(this.form).then((res) => {
        if (res.success) this.$bvModal.hide("residential-lease-modal");
      });
    },

    updateLeaseImage(e) {
      this.$refs.leasePreview.src = URL.createObjectURL(e.target.files[0]);
      this.leaseImage = e.target.files[0]
    },

    updateMarker(e) {
      this.form.renterLat = e.latLng.lat();
      this.form.renterLng = e.latLng.lng();
    },
  },

  computed: {
    getLeaseImage() {
      return require("@/assets/img/image-placeholder.png");
    },
  },
};
</script>