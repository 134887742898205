<template>
  <div>
    <div id="footer-wrapper" class="d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 footer-section">
            <ul class="list-unstyled footer-list">
              <li>
                <a :href="VUE_APP_USER_URL + '/login'">تسجيل دخول العميل</a>
              </li>
              <li>
                <a :href="VUE_APP_AGENT_URL + '/login'">تسجيل دخول الموظف</a>
              </li>
            </ul>
          </div>

          <div class="col-12 col-sm-6 col-md-4 footer-section">
            <ul class="list-unstyled footer-list">
              <li v-for="(cat, i) in categoriesForFooter" :key="i">
                <router-link
                  :to="{
                    name: 'category',
                    params: { categoryName: cat.name },
                  }"
                  >{{ cat.name }}</router-link
                >
              </li>
            </ul>
          </div>

          <div
            v-if="settings"
            class="col-12 col-md-4 text-center footer-section"
          >
            <div class="social-links text-center">
              <div class="row">
                <div class="col">
                  <img
                    id="snapchat-img"
                    src="@/assets/img/snapchat.png"
                    alt=""
                  />
                </div>
                <div
                  class="col d-flex align-items-center justify-content-center"
                >
                  <div>
                    <span>{{ settings.mobile }}</span>
                    <br>
                    <span>{{ settings.email }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-rights">
              <span>جميع الحقوق محفوظة. أثبات العقارية &copy;2020</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="developer-link d-block">
      <span>تطوير</span> <a href="https://khaledapps.com" target="_blank">khaledapps</a>
    </div>
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/settings";
import CategoriesMixin from "@/mixins/categories";

export default {
  mixins: [SettingsMixin, CategoriesMixin],
};
</script>